// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import {store} from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import CxltToastr from 'cxlt-vue2-toastr'
// import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import './assets/scss/cxlt-vue2-toastr.css'
import pagination from 'vuejs-uib-pagination'
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
import JsonExcel from 'vue-json-excel'
// import bPopover from 'bootstrap-vue/es/components/popover/popover'
import bPopover from 'bootstrap-vue'


import VueI18n from 'vue-i18n'
Vue.use(VueI18n)


import messages from './assets/language.json'

const i18n = new VueI18n({
  locale: 'th', // set locale
  messages, // set locale messages
})

import Datetime from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

import VueApexCharts from 'vue-apexcharts'

Vue.component('apexchart', VueApexCharts)

Vue.use(Datetime)



Vue.component('b-popovers', bPopover)
Vue.component('downloadExcel', JsonExcel)
var toastrConfigs = {
  position: 'top full width',
  showDuration: 1000,
  timeOut: 4000,
  hideMethod: 'zoomOut',
  progressBar: true
}

import VueProgressBar from 'vue-progressbar'
const options = {
  color: '#33adff',
  failedColor: 'red',
  thickness: '6px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
}
Vue.use(VueProgressBar, options)

Vue.use(CxltToastr, toastrConfigs)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCUQa-xmklN2jDLEH3nYlutbj3QKnZ0UGU'
    // librar ies: 'places' // This is required if you use the Autocomplete plugin
  }
})
Vue.use(BootstrapVue)
Vue.use(pagination)
Vue.use(require('vue-moment'))

import Autocomplete from './custom_modules/vuejs-autocomplete-master/src/components/Autocomplete'
//import Autocomplete from './custom_modules/autocomplete'
Vue.component('autocomplete', Autocomplete)

// todo
// cssVars()

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  store,
  i18n,
  components: {
    App
  }
})
