import roleCtrl from './roleCtrl'
import { APIENDPOINT, getHeaderUser } from './app.config'
import axios from 'axios'
const apiVerion = '/v1'

export default {
  downloadExcel(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params,
      responseType: 'blob'
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/balancefuel`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getUser() {
    var headers = { headers: getHeaderUser() }
    return new Promise((resolve, reject) => {
      axios.get(APIENDPOINT + apiVerion + '/user?', headers
      ).then((res) => {
        resolve(res)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  isAdmin(role) {
    if (roleCtrl.admin.indexOf(role) !== -1) {
      return true
    } else {
      return false
    }
  },
  isDealer(role) {
    if (roleCtrl.dealer.indexOf(role) !== -1) {
      return true
    } else {
      return false
    }
  },
  addCommas(nStr) {
    nStr += ''
    var x = nStr.split('.')
    var x1 = x[0]
    var x2 = x.length > 1 ? '.' + x[1] : ''
    var rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2')
    }
    return x1 + x2
  },
  tokenExpire() {
    window.localStorage.clear()
    window.location.reload()
  },
  showError(err) {
    let error = {}
    if (err.errorCode.code === 701) {
      error = [
        'ไม่สามารถดำเนินการได้',
        `code:${err.errorCode.code}`,
        `กรุณากรอกข้อมูลให้ครบถ้วน [${err.errors[0].param}]`,
      ]
    } else {
      error = [
        'ไม่สามารถดำเนินการได้',
        `code:${err.errorCode}`,
        `${err.errorText}`,
      ]
    }
    return error
  },
  catchError(err) {
    //    console.log(err)
    //    const errorText = {
    //      error: err.response.data,
    //      code: err.response.status,
    //      text: err.response.statusText
    //    }
    //    const errorText = err
    //    return errorText
  },
  //////////////////////////////////////////////////////
  // FOR USER 
  getUserInfo(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/user/info`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  editUserInfo(info) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/user/info`, info, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  // ////////////////////////////////////////////////////////////////////////////////
  // FOR TRANSACTION
  getTransaction(page, rows, id, machineid, device, product, qty, price, acceptorMoney, status, from, to, changerMoney, paymentType, refNumber, type) {
    var header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/transactions?page=${page}&rows=${rows}&from=${from}&to=${to}&transactionId=${id}&deviceId=${device}&productId=${product}&qty=${qty}&price=${price}&acceptorMoney=${acceptorMoney}&status=${status}&machineId=${machineid}&changerMoney=${changerMoney}&paymentType=${paymentType}&refNumber=${refNumber}&type=${type}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getTransactionV2(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/transactions`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  // //////////////////////////////////////////////////////////////////////
  // FOR PRODUCT
  uploadImage(img) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/upload`, img, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createProduct(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/product`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  updateProduct(data, id) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/product/${id}`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getProduct(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/products`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getProductId(id) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/product/${id}?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getProductList(params) {
    // const header = getHeaderUser()
    // return new Promise(function (resolve, reject) {
    //   axios.get(APIENDPOINT + apiVerion + `/products/list?type=${type}`, {headers: header})
    //     .then(function (res) {
    //       resolve(res)
    //     })
    //     .catch(function (err) {
    //       reject(err)
    //     })
    // })

    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/products/list`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  /// ////////////////////////////////////////////////////////////////////////
  // for Create machine
  getEvent(page, row, machineid, eventid, event, level, from, to) {
    const header = getHeaderUser()
    // console.log(APIENDPOINT + apiVerion + `/events?from=${from}&to=${to}&page=${page}&row=${row}&event=${event}&level=${level}`, {headers: header})
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/events?from=${from}&to=${to}&page=${page}&rows=${row}&event=${event}&level=${level}&eventId=${eventid}&machineId=${machineid}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getEventDefine() {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/event/define?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachineType() {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machine/type?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachineModels(type) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machine/models/${type}?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getUserList(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/user/list`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getSimList(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/sim/list`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getSim(provider) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/sim/list/${provider}?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getCoin() {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/coin/acceptor?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getBill() {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/bill/acceptor?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createMachine(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/machine`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createMachineV2(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/machine/create`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createSlots(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/slots/create`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachine(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machines`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachineList() {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machines/list?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachineDetails(id) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machine/${id}?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  updateMachine(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/machine/${data.id}`, data, { headers: header })
        // axios.get(APIENDPOINT + apiVerion + `/products`, {headers: header}, data)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getCommands(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/cmd`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  setCommands(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/cmd/command`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getCommandList(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/cmd/commands?page=${data.page}&rows=${data.rows}&machineId=${data.machineId}&cmdId=${data.cmdId}&from=${data.from}&to=${data.to}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  cancelCmd(seq) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/cmd/cancel`, { sequence: seq }, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachineSlots() {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machineslots?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getSlots(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machines/slots?machineId=${data}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachineStatus() {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machinestatus?`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMachineProfileV2(machineId) {
    const header = getHeaderUser()
    const config = {
      headers: header
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/machineV2/${machineId}`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  updateMachineV2(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/machine/update/${data.id}`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  // //////////////////////////////////////////////////////////
  // Report
  getReportByProduct(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/machine/${data.id}?from=${data.start}&to=${data.end}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getReportByUserDashboard(data) {
    const header = getHeaderUser()
    // console.log(APIENDPOINT + apiVerion + `/report/user?from=${data.start}&to=${data.end}`)
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/user?from=${data.start}&to=${data.end}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getReportByUser(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/users?from=${data.start}&to=${data.end}&productId=${data.productId}&machineId=${data.machineId}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getClearInboxReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/clearmoney/list?from=${data.from}&to=${data.to}&machineId=${data.machineId}&page=${data.page}&rows=${data.rows}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getChartsReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/charts/${data.mode}?machineId=${data.machineId}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getChartsUserReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/usercharts/${data.mode}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getChartSaleReport(params) {
    const header = getHeaderUser()
    const configs = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/chartsalereport`, configs)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getTransactionsReport(id, machineid, slot, product, qty, price, acceptorMoney, status, from, to, changerMoney, paymentType, refNumber, type) {
    const header = getHeaderUser()
    const configs = {
      headers: header,
      params: {
        transactionId: id,
        machineId: machineid,
        slotId: slot,
        productId: product,
        qty: qty,
        price: price,
        acceptorMoney: acceptorMoney,
        status: status,
        from: from,
        to: to,
        changerMoney: changerMoney,
        paymentType: paymentType,
        refNumber: refNumber,
        type: type
      }
    }

    // console.log(APIENDPOINT + apiVerion + `/report/transactions`, configs)
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/transactions`, configs)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getTransactionsReportNew(params) {
    const header = getHeaderUser()
    const configs = {
      headers: header,
      params: params
    }
    // console.log(APIENDPOINT + apiVerion + `/report/transactions`, configs)
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/transactions`, configs)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getTransactionSum(params) {
    const header = getHeaderUser()
    const configs = {
      headers: header,
      params: params
    }
    // console.log(APIENDPOINT + apiVerion + `/report/transactions`, configs)
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/transactionsum`, configs)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getSaleReport(params) {
    const header = getHeaderUser()
    const configs = {
      headers: header,
      params: params
    }
    // console.log(APIENDPOINT + apiVerion + `/report/transactions`, configs)
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/salereport`, configs)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  // //////////////////////////////////////////////////////////
  // User
  createUser(user) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/user/create`, user, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  changePwd(user) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/user/edit/pwd`, user, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  addCredit(body) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/usercredit`, body, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  addCreditSMS(body) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/usercreditsms`, body, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  // ///////////////////////////////////////////////////////////
  // Sims
  createSim(sim) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/sims/create`, sim, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  editSim(sim) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/sims/edit`, sim, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  // /////////////////////////////////////////////////////////////////////////
  // sms
  smsStatus(sms) {
    const header = getHeaderUser()
    const params = {
      transactionId: sms.transactionId
    }
    const configs = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      // axios.get(APIENDPOINT + apiVerion + `/sms/status`, {headers: header}, {params: params})
      axios.get(APIENDPOINT + apiVerion + `/sms/status`, configs)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  smsTransactions(params) {
    const header = getHeaderUser()
    const configs = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      // axios.get(APIENDPOINT + apiVerion + `/sms/status`, {headers: header}, {params: params})
      axios.get(APIENDPOINT + apiVerion + `/sms/transactions`, configs)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getCredits(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/credits`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  credits(body) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/credits/${body.paymentType}`, body, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  resetPasswrod(body) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/password/reset`, body, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  ////////////////////////////////////////////////////////////////////////
  // promotions
  createPromotion(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/promotions/create`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  editPromotion(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/promotions/edit`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getPromotionList(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/promotions`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  deletePromotion(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.delete(APIENDPOINT + apiVerion + `/promotion`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getPromotion(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/promotion`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getPromotionType(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/promotions/type`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },


  ////////////////////////////////////////////////////////////////////////////////////
  // devices
  createDevices(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/device/create`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getDeviceType(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/device/type`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getDevice(machineId) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/device/${machineId}`, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  updateSlots(slots) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/slots/update`, slots, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  deleteDevice(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.delete(APIENDPOINT + apiVerion + `/devices/delete`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  deleteSlots(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.delete(APIENDPOINT + apiVerion + `/slots/delete`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  //////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// New Report ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  getUserReport(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/userstatistic`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getIncompleteReport(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/incompletereport`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createIncompleteReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/report/incompletereport`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  updateIncompleteReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/report/incompletereport`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMaintenanceHistoryReport(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/maintenancehistoryreport`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createMaintenanceHistoryReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/report/maintenancehistoryreport`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getMaintenanceRemainderReport(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/maintenanceremainderreport`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createMaintenanceRemainderReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/report/maintenanceremainderreport`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getElectricityProblemReport(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/electricityproblemreport`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createElectricityProblemReport(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/report/electricityproblemreport`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  uploadElectricityProblemReport(data) {
    const header = getHeaderUseWr()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/report/electricityproblemreport`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },


  //// new dashboard
  getDonutChartTransaction(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/dashbord/donuttransactions`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getDonutDeviceStatus(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/report/dashbord/donutdevicestatus`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },

  // devices
  getDevices(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/devices`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  createDevice(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + apiVerion + `/devices`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  updateDevice(data) {
    const header = getHeaderUser()
    return new Promise(function (resolve, reject) {
      axios.put(APIENDPOINT + apiVerion + `/devices`, data, { headers: header })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },

  //////////////////////////////////////////////
  //// Coupon
  getCoupons(params) {
    const header = getHeaderUser()
    const config = {
      headers: header,
      params: params
    }
    return new Promise(function (resolve, reject) {
      axios.get(APIENDPOINT + apiVerion + `/coupons`, config)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
}
