import Vue from "vue"
import Router from "vue-router"

// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer")

// Views
const Dashboard = () => import("@/views/Dashboard")
const Orders = () => import("@/views/Orders")

// Machine
const Machines = () => import("@/views/machines/Machines")
const NewMachine = () => import("@/views/machines/NewMachine")
const SimManager = () => import("@/views/machines/SimManager")
const Setting = () => import("@/views/machines/Setting")
const EventMachine = () => import("@/views/machines/EventMachine")
const Commands = () => import("@/views/machines/Commands")
const ProblemReport = () => import("@/views/machines/ProblemReport")

// Products
const Products = () => import("@/views/products/Products")
const NewProduct = () => import("@/views/products/NewProduct")
const Categories = () => import("@/views/products/Categories")

const Devices = () => import("@/views/Devices")

// Report
const Reports = () => import("@/views/reports/Reports")
const SalesByDealer = () => import("@/views/reports/SalesByDealer")
const SalesByUser = () => import("@/views/reports/SalesByUser")
const SalesByMachine = () => import("@/views/reports/SalesByMachine")
const ClearMoneyReport = () => import("@/views/reports/ClearMoneyReport")
const SMSreport = () => import("@/views/reports/SMSreport")
const UserReport = () => import("@/views/reports/UserReport")
const IncompleteReport = () => import("@/views/reports/IncompleteReport")
const MaintenanceHistoryReport = () =>
  import("@/views/reports/MaintenanceHistoryReport")
const MaintenanceRemainderReport = () =>
  import("@/views/reports/MaintenanceRemainderReport")
const ElectricityProblemReport = () =>
  import("@/views/reports/ElectricityProblemReport")
const CreditRefundReport = () =>
  import("@/views/reports/CreditRefundReport")

// Pages
const Login = () => import("@/components/login/login")
const Home = () => import("@/views/Home")

// Users
const Users = () => import("@/views/users/Users")
const NewUser = () => import("@/views/users/NewUser")
const CustomerCredit = () => import("@/views/users/CustomerCredit")

// Promotion
const Promotion = () => import("@/views/promotion/Promotion")
const EditPromotion = () => import("@/views/promotion/EditPromotion")
const CreatePromotion = () => import("@/views/promotion/CreatePromotion")

Vue.use(Router)

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/home",
      name: "Home",
      component: DefaultContainer,
      children: [
        {
          path: "/home",
          name: "Home",
          component: Home
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard
        },
        {
          path: "/orders",
          name: "Orders",
          component: Orders
        },
        {
          path: "/machine",
          redirect: "/machine/machines",
          name: "Machine",
          component: {
            render(c) {
              return c("router-view")
            }
          },
          children: [
            {
              path: "/machine/machines",
              name: "Machines",
              component: Machines
            },
            {
              path: "/machine/setting/:id",
              name: "Setting",
              component: Setting,
              props: true
            },
            {
              path: "/machine/new_machine",
              name: "New Machine",
              component: NewMachine
            },
            {
              path: "/machine/event_machine",
              name: "Event Machine",
              component: EventMachine
            },
            {
              path: "/machine/commands",
              name: "Commands",
              component: Commands
            },
            {
              path: "/machine/problemreport",
              name: "Problem Report",
              component: ProblemReport
            }
          ]
        },
        {
          path: "/sim",
          redirect: "/sim/sims",
          name: "Sims",
          component: {
            render(c) {
              return c("router-view")
            }
          },
          children: [
            {
              path: "/sim/sims",
              name: "Sim Manager",
              component: SimManager
            }
          ]
        },
        {
          path: "/product",
          redirect: "/product/products",
          name: "Product",
          component: {
            render(c) {
              return c("router-view")
            }
          },
          children: [
            {
              path: "/product/products",
              name: "Products",
              component: Products
            },
            {
              path: "/product/new_product/:mode/:productid",
              name: "New Product",
              component: NewProduct
            },
            {
              path: "categories",
              name: "Categories",
              component: Categories
            }
          ]
        },
        {
          path: "user",
          redirect: "/user/users",
          name: "User",
          component: {
            render(c) {
              return c("router-view")
            }
          },
          children: [
            {
              path: "/user/users",
              name: "Users",
              component: Users
            },
            {
              path: "/user/new_user",
              name: "NewUser",
              component: NewUser
            },
            {
              path: "/user/customercredit",
              name: "CustomerCredit",
              component: CustomerCredit
            }
          ]
        },
        {
          path: "/devices",
          name: "Devices",
          component: Devices
        },
        {
          path: "report",
          redirect: "/report/reports",
          name: "Report",
          component: {
            render(c) {
              return c("router-view")
            }
          },
          children: [
            {
              path: "/report/reports",
              name: "Reports",
              component: Reports
            },
            {
              path: "/report/sales_by_dealer",
              name: "Sales by Dealer",
              component: SalesByDealer
            },
            {
              path: "/report/sales_by_user",
              name: "Sales by User",
              component: SalesByUser
            },
            {
              path: "/report/sales_by_machine",
              name: "Sales by Machine",
              component: SalesByMachine
            },
            {
              path: "/report/clear_inbox_report",
              name: "Clear Inbox Report",
              component: ClearMoneyReport
            },
            {
              path: "/report/sms_report",
              name: "SMS Report",
              component: SMSreport
            },
            {
              path: "/report/user_report",
              name: "User Report",
              component: UserReport
            },
            {
              path: "/report/incomplete_report",
              name: "Incomplete Report",
              component: IncompleteReport
            },
            {
              path: "/report/maintenance_history_report",
              name: "Maintenance History Report",
              component: MaintenanceHistoryReport
            },
            {
              path: "/report/maintenance_remainder_report",
              name: "Maintenance Remainder Report",
              component: MaintenanceRemainderReport
            },
            {
              path: "/report/electricity_problem_report",
              name: "Electricity Problem Report",
              component: ElectricityProblemReport
            },
            {
              path: "/report/credit_refund_report",
              name: "Credit Refund Report",
              component: CreditRefundReport
            }
          ]
        },
        {
          path: "/promotion",
          name: "Promotion",
          redirect: "/promotion/all",
          component: {
            render(c) {
              return c("router-view")
            }
          },
          children: [
            {
              path: "/promotion/all",
              name: "Promotions",
              component: Promotion
            },
            {
              path: "/promotion/edit/:machineId/:promotionId",
              name: "EditPromotion",
              component: EditPromotion
            },
            {
              path: "/promotion/create",
              name: "CreatePromotion",
              component: CreatePromotion
            }
          ]
        }
      ]
    },
    {
      path: "/",
      name: "",
      component: {
        render(c) {
          return c("router-view")
        }
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login
        },
        {
          path: "home",
          name: "home",
          component: Home
        }
      ]
    }
  ]
})
