<template>
    <div id="app">
        <meta charset="UTF-8">
        <router-view></router-view>
        <vue-progress-bar></vue-progress-bar>
    </div>

</template>

<script>
import script from './script'
export default {
  name: 'app',
  mounted () {
    if (localStorage.getItem('isLogin')) {
      script.getUser().then((res) => {
        console.log('Get user success, App')
        let lang = window.localStorage.getItem('language')
        if (!lang) {
          lang = 'th'
          window.localStorage.setItem('language', lang)
          this.$i18n.locale = lang
        } else {
          this.$i18n.locale = lang
        }
        this.$store.state.nav = this.$t("message.nav")
        this.$store.state.users = res.data
        // window.localStorage.setItem('users', res.data)

      }).catch(function (err) {
        // alert('หมดเวลาการเชื่อมต่อ กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
        localStorage.clear()
        window.location.reload()
        console.log('Cant get user, App ' + err)
      })
    } else {
      console.log('Please login, APP')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
