//export const APIENDPOINT = 'http://localhost:3000/api'
//export const APIENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'http://api:3000/api'


// export const APIENDPOINT = '/api'
export const APIENDPOINT = returnEndPointAPI()

export const getHeader = function () {
  const headers = {
    'Content-Type': 'application/json'
  }
  return headers
}

export const getHeaderUser = function () {
  const tokenData = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'bearer ' + tokenData
  }
  return headers
}

function returnEndPointAPI () {
  if (process.env.NODE_ENV === 'production') {
    return '/api'//'VUE_APP_API_ENDPOINT'
  } else {
    return process.env.VUE_APP_API_ENDPOINT
  }
}



